import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Hero, Intro } from "../../components/StandartPage/index"
import { Services, Steps } from "../../components/Sections/index"
import BlogCard from "../../components/PostCard/PostCardGroup"
import Seo from "../../components/seo"

const SosyalMedyaAvukatiPage = ({ data: { blog } }) => (
  <Fragment>
    <Seo
      title="Sosyal Medya Avukatı"
      description="Her büyüklükteki sosyal medya kullanıcıları için uzman hukuki danışmanlık sunuyoruz. Fenomen, Influencer ve Marka Avukatlığı."
    />
    <Hero
      title="Sosyal Medya Avukatı"
      subtitle="Her büyüklükteki sosyal medya kullanıcıları için uzman hukuki danışmanlık sunuyoruz. Uzman teknolojimiz ve bilişim avukatlarımız, yasal gereksinimlerinize yardımcı olacak zengin bir deneyime sahiptir."
    />
    <Intro
      description="
      Teknoloji, pazarlamacıların sosyal medya avukatlarının tüketicilerle etkileşim kurmaları için yeni kanallar yarattı. Sosyal medya kampanyaları ve promosyonları, bir şirketin entegre pazarlama çabaları için hızla gerekli hale geldi. Sosyal medya benzersiz fırsatlar sunarken, karmaşık yasal uyumluluk tavsiyeleri kritik öneme sahiptir. Pazarlamacılar ve markalar, sosyal medyanın hâlâ ticari reklamcılık olduğu ve geleneksel reklamcılık yasalarının geçerli olduğu konusunda dikkatli olmalıdır. Bu kanalların kendi gereksinimleri olsa da, reklam iddiaları, tüketici onayları, ticari markaların kullanımı, ünlü resimleri ve benzerleriyle ilgili uyumluluk yükümlülükleri büyük önem taşımaktadır.
      <br/> <br/>
      Amacımız, müvekillerimizin sosyal medya kullanımlarını optimize etmeye çalışırken uyum ihtiyaçlarını karşılamalarına yardımcı olmaktır. Bu amaçla sosyal medya avukatlarımız, müvekkillerimizin Facebook, Twitter, YouTube, Pinterest, Instagram ve diğer popüler sosyal medya sitelerinde sosyal medya tanıtımlarını yapılandırmalarına yardımcı olur. Ayrıca müşterilere, tüketici onayları ve ürün ve hizmet referansları için kullanıcı tarafından oluşturulan içerikten yararlanmanın doğasında var olan tuzaklardan nasıl kaçınılacağı konusunda tavsiyelerde bulunuyoruz.
      <br/><br/>
      Facebook, Promosyon Yönergelerini revize etmeye devam ediyor. Facebook'ta promosyon yapmak isteyen herhangi bir markanın, promosyonun yalnızca ilgili federal ve eyalet yasalarına değil, aynı zamanda en son platform yönergelerine de uygulanmasını sağlamak için en son Facebook Promosyon Yönergelerini anlayan bir avukatla çalışması zorunludur.
      <br/><br/>
      Tweetlerdeki 140 karakterlik sınır, Twitter tabanlı promosyonların yasallığı için önemli bir zorluk teşkil ediyor. Genel olarak, bir Twitter kullanıcısının ödül kazanmaya uygun olması için, ödülü veren hesabı takip etmesi ve ardından promosyonu retweet etmesi veya tweet'lerine dahil etmesi gerekir. Promosyonun Twitter Yarışma Yönergeleri ile uyumlu olmasını sağlamak, çekiliş veya yarışmanızın yasallığını sağlamak için yeterli değildir. Deneyimli bir Sosyal Medya Avukatı ile çalışmak, soruşturma, para cezası ve dava riskini en aza indirmek için çok önemlidir.
      <br/><br/>
      Sosyal medya günlük olarak yüz milyonlarca insanı etkilediğinden, on yıldan daha eski olduğunu unutmak kolaydır. Sosyal medya çok yeni bir ortam olduğu için, federal ve eyalet kurumları tarafından düzenlenme şekli ve platformların kendileri hala gelişmektedir. Sosyal medya avukatlarımız, sosyal medya hukuku gelişmelerini yakından takip eder ve şirketinizin kampanyasının uyumlu olmasını sağlamaya yardımcı olmak için çalışır.
      <br/><br/>
      Sosyal medya, birçok heyecan verici fırsatın yanı sıra birçok zorluk da sunuyor. Her fırsattan en iyi şekilde yararlanmanıza ve her türlü zorluğun üstesinden gelmenize yardımcı olabilecek bir sosyal medya hukuk firması istiyorsanız, şirketinizin sosyal medya ihtiyaçlarını görüşmek için bizimle iletişime geçin.
     "
    />
    <Services />
    <Steps />
    <BlogCard posts={blog.edges} />
  </Fragment>
)

export default SosyalMedyaAvukatiPage

export const blogQuery = graphql`
  {
    blog: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            tags
            title
            description
            Image {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
